@import './bootstrap.scss';
@import './fonts.scss';
@import './kendo.scss';

/*
Compile time variables - not for direct usage
*/
$primary: #3a2456;
$primary-light: rgba(
  $color: $primary,
  $alpha: 0.1,
);
$primary-5: rgba(
  $color: $primary,
  $alpha: 0.05,
);
$primary-60: rgba(
  $color: $primary,
  $alpha: 0.6,
);
$secondary: #9460a4;
$secondary-light: rgba(
  $color: $secondary,
  $alpha: 0.1,
);
$secondary-dark: #3a2456;
$border-color: #9fa0a4;
$border-color-light: rgba(
  $color: $border-color,
  $alpha: 0.25,
);

/*
CSS Variables
*/
:root {
  --s3-btn-bg-color: #{$primary};
  --s3-btn-font-color: #ffffff;
  --s3-btn-font-size: 1rem;
  --s3-btn-padding: 1rem 5rem;
  --s3-btn-padding-md: 0.5rem 3rem;
  --s3-btn-padding-sm: 0.35rem 2rem;
  --s3-btn-border: none;

  /* Styles for the non-main button */
  --s3-btn-minor-bg-color: hsla(216, 98%, 59%, 0.05);
  --s3-btn-minor-bg-color-hover: hsla(216, 98%, 59%, 0.15);
  --s3-btn-minor-bg-color-active: hsla(216, 98%, 59%, 0.25);

  --s3-input-padding: 1rem 1.5rem;
  --s3-input-padding-sm: 0.25rem 1.5rem;
  --s3-input-font-size: 1rem;
  --s3-input-font-color: #7d8086;
  --s3-input-border: 1px solid #{$border-color};
  --s3-input-max-height: 58px;

  --s3-font-primary: #{$primary};
  --s3-font-secondary: #{$secondary};
  --s3-font-regular: Nunito-Regular;
  --s3-font-bold: Nunito-Bold;
  --s3-font-light: Nunito-Light;
  --s3-font-size-heading-large: 50px;
  --s3-font-size-heading-x-large: 60px;
  --s3-font-size-lg: 1.125rem;
  --s3-font-size-heading: 1.5rem;
  --s3-font-size-50x-large: 100px;
  --s3-font-size-6x-large: 40px;
  --s3-font-size-5x-large: 35px;
  --s3-font-size-3x-large: 28px;
  --s3-font-size-2x-large: 24px;
  --s3-font-size-1x-large: 22px;
  --s3-font-size-x-large: 20px;
  --s3-font-size-large: 16px;
  --s3-font-size-regular: 14px;
  --s3-font-size-x-medium: 13px;
  --s3-font-size-medium: 12px;
  --s3-font-size-small: 10px;
  --s3-font-size-xsmall: 8px;
  --s3-font-weight-heading: 400;
  --s3-font-weight-light: 100;

  --s3-border-color: #{$border-color};
  --s3-border-color-20: #{$border-color-light};
  --s3-border-radius: 0.75rem;
  --s3-border-radius-lg: 1rem;
  --s3-border-radius-xl: 2rem;
  --s3-border-radius-tile: 4px;

  --s3-color-primary: #{$primary};
  --s3-color-primary-light: #1cbec9;
  --s3-color-primary-10: #{$primary-light};
  --s3-color-primary-5: #{$primary-5};
  --s3-color-primary-60: #{$primary-60};
  --s3-color-secondary: #{$secondary};
  --s3-color-secondary-light: #{$secondary-light};
  --s3-color-secondary-dark: #{$secondary-dark};
  --s3-color-secondary-10: rgba(148, 96, 164, 0.1);
  --s3-color-secondary-7: rgba(148, 96, 164, 0.7);
  --s3-color-default: #353935;
  --s3-color-default-10: #fafafa;
  --s3-color-default-11: #dadada;
  --s3-color-default-12: #cccccc;
  --s3-color-default-25: #9d9a9a;
  --s3-color-default-light: #e8f8fa;
  --s3-color-default-dark: #57595e;
  --s3-color-heading: #000;
  --s3-color-mute: #7d8086;
  --s3-color-default-20: #f7f7f7;
  --s3-color-default-dark-10: #9fa0a433;
  --s3-color-default-dark-20: rgba(159, 160, 164, 0.2);
  --s3-color-validation: #fc5a5a;
  --s3-color-validation-bg: #faeaef;
  --s3-color-scrollbar: #c1c1c1;
  --s3-color-progressbar-1: #f6c64b1a;
  --s3-color-progressbar-2: #f6c64b;
  --s3-color-progressbar-3: #c9315f1a;
  --s3-color-progressbar-4: #772e44;
  --s3-color-warning: rgba(246, 198, 75, 0.1);
  --s3-color-default-dark-100: #9fa0a4;
  --s3-color-dark: #333333;
  --s3-color-off-white: rgba(255, 255, 255, 0.5);

  --s3-color-highlight-yellow: hsla(43, 87%, 58%, 1);
  --s3-bg-highlight-yellow: hsla(43, 90%, 63%, 0.1);
  --s3-color-highlight-green: hsla(139, 100%, 29%, 1);
  --s3-bg-highlight-green: hsla(131, 59%, 52%, 0.05);
  --s3-color-highlight-green-light: hsla(131, 59%, 52%, 1);
  --s3-bg-highlight-green-light: hsla(131, 59%, 52%, 0.1);
  --s3-color-icon-green-light: rgba(61, 205, 88, 0.2);
  --s3-color-icon-green: rgba(0, 149, 48, 1);
  --s3-background-color-icon-green: rgba(0, 149, 48, 0.1);
  --s3-color-highlight-red: hsla(0, 96%, 67%, 1);
  --s3-bg-highlight-red: rgba(252, 90, 90, 0.1);

  --s3-color-pie-dashboard: #f5bf6d;
  --s3-color-primary-05: #3082fd0d;
  --s3-color-tags-blue: #993ce2;
  --s3-color-tags-blue-10: #993ce21a;

  --s3-padding: 1rem;
  --s3-padding-lg: 1.5rem;
  --s3-color-alert: hsla(0, 73%, 54%, 1);
  --s3-color-alert-background: hsla(0, 73%, 54%, 0.05);
  --s3-color-alert-dark: #cd2b31;
  --s3-color-background-primary: #fafafa;
  --s3-color-background-primary-10: #f7f7f7;
  --s3-color-background-primary-20: #9fa0a433;
  --s3-white: #ffffff;
  --s3-color-background-light: #f9f7fa;
  ---s3-color-secondry-light: #42b4e6;
  ---s3-color-secondry-light-mute: #42b4e629;
  ---s3-color-light-blue: #dbeffa;
  ---s3-color-primary-mute: rgba(148, 96, 164, 0.05);
  --s3-shadow: rgba(79, 79, 79, 0.16);
  --s3-color-orange: #ff9900;
  --s3-color-orange-mute: #ff99000d;
  --s3-color-performance-color-1: #0044f3;
  --s3-color-performance-color-2: #21dcba;
  --s3-color-performance-color-3: #0f84b2;
  --s3-color-performance-color-3-light: rgba(15, 132, 178, 0.05);
  --s3-color-performance-low-red: #c9315f;
  --s3-color-background-performance-low-red: rgba(201, 49, 95, 0.05);
  --s3-background-color-icon-green-dark: rgba(0, 149, 48, 0.2);
  --s3-color-background-performance-low-red-1: rgba(201, 49, 95, 0.1);
  --s3-color-performance-brand-green: #009530;
  --s3-color-performance-brand-orange: #f1bd35;
  --s3-color-orange-10: rgba(201, 122, 49, 0.1);
  --s3-color-grid-grey: rgba(246, 246, 246, 1);
  --s3-color-grid-grey-shade: rgba(246, 244, 247, 1);
  --s3-color-black: #000000;
}

body {
  font-family: var(--s3-font-regular);
  color: var(--s3-color-default);
}

a {
  cursor: pointer;
  text-decoration: none;
  color: var(--s3-font-primary);
  font-weight: var(--s3-font-weight-heading);
}

.font-light {
  font-family: var(--s3-font-light);
}

.font-bold {
  font-family: var(--s3-font-bold);
}

.modal-xl .modal-lg {
  max-width: 90%;
}

.font-heading {
  font-size: var(--s3-font-size-large);
  font-weight: var(--s3-heading-font-weight);
}

.font-primary {
  color: var(--s3-font-primary);
}

.font-secondary {
  color: var(--s3-font-secondary);
}

.font-alert {
  color: var(--s3-color-alert);
}

.font-size-lg {
  font-size: var(--s3-font-size-lg);
}

.font-size-large {
  font-size: var(--s3-font-size-large);
}

.font-1x-size-lg {
  font-size: var(--s3-font-size-1x-large);
}

.font-size-md {
  font-size: var(--s3-font-size-medium);
}

.font-size-sm {
  font-size: var(--s3-font-size-small);
}

.font-size-xsm {
  font-size: var(--s3-font-size-xsmall);
}

.s3-card-icon {
  display: flex;
  width: 50px;
  height: 50px;
  background: var(--s3-color-secondary-light);
  border-radius: 8px;
  align-items: center;
  color: var(--s3-color-secondary-dark);
  justify-content: center;
  font-size: 30px;
}

.s3-form-element-group {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 2rem;
  align-items: center;

  .label {
    width: 10rem;
  }

  .form-element {
    padding-left: 4rem;
    flex-grow: 1;
  }
}

.s3-hr {
  border: 1px solid var(--s3-color-default-25);
  margin: 15px 0px;
}

.s3-hr-light {
  border: 1px solid var(--s3-color-default-11);
  margin: 15px 0px;
}

.s3-validation-text {
  display: block;
}

.s3-flex-center {
  align-items: center;
  display: flex;
}

/*common class for control with label on top*/
.s3-form-element-label {
  background-color: white;
  position: relative;
  top: 12px;
  left: 20px;
  z-index: 1;
  padding: 4px;
  font-size: var(--s3-font-size-regular);
}

/*Custom ScrollBars styles starts here */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-thumb {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: rem;
  gap: 1rem;
  width: 0.8rem;
  background: var(--s3-color-scrollbar);
  border-radius: 0.4rem;
}

.highlight-yellow {
  color: var(--s3-color-highlight-yellow);
  background: var(--s3-bg-highlight-yellow);
}

.highlight-green {
  color: var(--s3-color-highlight-green);
  background: var(--s3-bg-highlight-green);
}

#zuora_payment {
  iframe {
    min-width: 600px;
    margin: 0 auto;
  }
}

input::placeholder {
  opacity: 0.5;
  color: var(--s3-input-font-color);
}

textarea::placeholder {
  opacity: 0.5;
  color: var(--s3-input-font-color);
}

.s3-placeholder {
  opacity: 0.5;
  color: var(--s3-input-font-color);
}

.gblNoNavWrapper {
  width: 70%;
  margin: 20px auto;
  --s3-tab-header-left-margin: 400px;
}

ngx-spinner {
  div {
    z-index: 1000002 !important;
  }
}

.btn-close:focus {
  box-shadow: none;
}

.dropdown-hide-icon .k-input-button {
  display: none;
  position: relative;
}

.pointer {
  cursor: pointer;
}

.s3-title {
  font-size: var(--s3-font-size-lg);
}

.s3-heading {
  font-size: var(--s3-font-size-heading);
  font-family: var(--s3-font-bold);
}

.s3-medium {
  font-size: var(--s3-font-size-medium);
}

.s3-large {
  font-size: var(--s3-font-size-large);
}

.s3-3x-large {
  font-size: var(--s3-font-size-3x-large);
}

.s3-2x-large {
  font-size: var(--s3-font-size-2x-large);
}

.s3-x-large {
  font-size: var(--s3-font-size-x-large);
}

.s3-small {
  font-size: var(--s3-font-size-small);
}

.s3-xsmall {
  font-size: var(--s3-font-size-xsmall);
}

.s3-regular {
  font-size: var(--s3-font-size-regular);
}

.s3-bold {
  font-family: var(--s3-font-bold);
}

.s3-size-lg {
  font-size: var(--s3-font-size-lg);
}

.s3-heading-large {
  font-size: var(--s3-font-size-heading-large);
}

.s3-1x-large {
  font-size: var(--s3-font-size-1x-large);
}

.s3-5x-large {
  font-size: var(--s3-font-size-5x-large);
}

.s3-6x-large {
  font-size: var(--s3-font-size-6x-large);
  font-family: var(--s3-font-bold);
}

.cursor-ptr {
  cursor: pointer;
}

.s3-underline {
  text-decoration: underline;
}

.s3-italic {
  font-style: italic;
}

.s3-w-fit-content {
  width: fit-content;
}

.s3-w-fit-content {
  width: fit-content;
}

.s3-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.s3-font-light {
  font-weight: var(--s3-font-weight-light);
}

///// overriding loader text position //////////////////////
.loading-text {
  top: calc(50% + 70px) !important;
}

//copilot styles
.message-edit {
  padding: 28px 22px !important;
}

.message-edit.empty::after {
  bottom: 25px !important;
}
.message-send-btn {
  right: 10px !important;
}
